<!--
 * @Description: 客户基础信息
 * @Author: ChenXueLin
 * @Date: 2021-10-21 11:07:11
 * @LastEditTime: 2022-10-13 17:02:54
 * @LastEditors: ChenXueLin
-->
<template>
  <section class="edit-wrapper">
    <div class="edit-wrapper__body">
      <div class="corpInvoiceInfo-content">
        <!-- 开票信息 start -->
        <div class="baseInfo-box">
          <div class="edit-title">开票信息</div>
          <el-form
            class="editForm"
            label-width="140px"
            :inline="true"
            :model="corpInvoiceInfo"
            :rules="invoiceDataRules"
            ref="invoiceDataForm"
          >
            <el-form-item label="开票名称" prop="billingCorpId">
              <select-real-Time-query
                v-model="corpInvoiceInfo.billingCorpId"
                :defaultDataList="billingCorpNameEnum"
                :queryListAPI="getInvoiceDownList"
                :needInitDropDownList="false"
                :disabled="formCrmData"
                :showSlot="true"
                slotKey="taxNo"
                :popoverWidth="300"
                :searchData="{
                  pageIndex: 1,
                  pageSize: 200
                }"
                :propsConfig="{
                  id: 'billingCorpId',
                  label: 'billingCorpName'
                }"
                searchValueKey="billingCorpName"
                @change="billingChange"
                @clear="billingChange"
                title="开票名称"
                placeholder="开票名称"
                clearable
              ></select-real-Time-query>
            </el-form-item>
            <el-form-item label="客户名称" prop="bankNumber">
              <el-input
                disabled
                placeholder="客户名称"
                v-model.trim="corpInvoiceInfo.customerName"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业类型" prop="bssType">
              <e6-vr-select
                disabled
                v-model="corpInvoiceInfo.bssType"
                :data="bssTypeEnum"
                placeholder="企业类型"
                title="企业类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="纳税人类别" prop="invoicePeopleType">
              <e6-vr-select
                disabled
                v-model="corpInvoiceInfo.invoicePeopleType"
                :data="invoicePeopleTypeEnum"
                @change="invoicePeopleTypeChange"
                placeholder="纳税人类别"
                title="纳税人类别"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="开票类型" prop="invoiceType">
              <e6-vr-select
                disabled
                v-model="corpInvoiceInfo.invoiceType"
                :data="invoiceTypeEnum"
                @change="clearValidateInvoceForm"
                placeholder="开票类型"
                title="开票类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="纳税人识别编号" prop="taxNo">
              <el-input
                disabled
                placeholder="纳税人识别编号"
                v-model.trim="corpInvoiceInfo.taxNo"
                maxlength="32"
              ></el-input>
            </el-form-item>

            <el-form-item label="开户行名称" prop="bankName">
              <el-input
                disabled
                placeholder="开户行名称"
                v-model.trim="corpInvoiceInfo.bankName"
                maxlength="128"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行账号" prop="bankNumber">
              <el-input
                disabled
                placeholder="开户行账号"
                v-model.trim="corpInvoiceInfo.bankNumber"
                maxlength="128"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司电话" prop="phone">
              <el-input
                disabled
                placeholder="公司电话"
                v-model.trim="corpInvoiceInfo.phone"
                maxlength="128"
              ></el-input>
            </el-form-item>
            <el-form-item label="开户行地址" prop="bankAddress">
              <el-input
                disabled
                placeholder="开户行地址"
                v-model.trim="corpInvoiceInfo.bankAddress"
                maxlength="128"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 开票信息 end -->
        <!-- 基本信息 start -->
        <div class="baseInfo-box">
          <div class="edit-title">客户基础信息-CRM</div>
          <el-form
            ref="corpBaseFormCRM"
            label-width="140px"
            label-position="right"
            :inline="true"
            :model="corpBaseInfo"
            :rules="corpBaseRules"
            class="editForm"
          >
            <el-form-item label="系统客户名称" prop="corpName">
              <el-input
                placeholder="系统客户名称"
                v-model.trim="corpBaseInfo.corpName"
                :disabled="formCrmData"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="产品线" prop="productLine">
              <e6-vr-select
                v-model="corpBaseInfo.productLine"
                :data="corpProductLineTypeEnum"
                :disabled="formCrmData"
                placeholder="产品线"
                title="产品线"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="业务员" prop="salesmanName">
              <el-input
                v-model="corpBaseInfo.salesmanName"
                disabled
                placeholder="业务员"
                title="业务员"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属主体" prop="companyId">
              <el-input
                v-if="formCrmData"
                disabled
                placeholder="所属主体"
                v-model="corpBaseInfo.companyName"
              ></el-input>
              <e6-vr-select
                v-model="corpBaseInfo.companyId"
                v-else
                :data="companyEnum"
                placeholder="所属主体"
                title="所属主体"
                :props="{
                  id: 'companyId',
                  label: 'companyFullName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户类别" prop="corpCategory">
              <e6-vr-select
                v-model="corpBaseInfo.corpCategory"
                :disabled="formCrmData"
                :data="corpCategoryEnum"
                placeholder="客户类别"
                title="客户类别"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="业务类型" prop="corpBizType">
              <e6-vr-select
                v-model="corpBaseInfo.corpBizType"
                :disabled="formCrmData"
                :data="corpBizTypeEnum"
                placeholder="业务类型"
                title="业务类型"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户分级" prop="corpClass">
              <e6-vr-select
                v-model="corpBaseInfo.corpClass"
                :disabled="formCrmData"
                :data="corpClassEnum"
                placeholder="客户分级"
                title="客户分级"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户地址">
              <div class="address-box">
                <city
                  @selected="handleCitySelect"
                  ref="citySelect"
                  :disabled="formCrmData"
                  :defaultCity.sync="corpBaseInfo.defualtCity"
                ></city>
                <el-input
                  placeholder="详细地址"
                  v-model="corpBaseInfo.address.addressDetail"
                  :disabled="formCrmData"
                  maxlength="128"
                ></el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div class="baseInfo-box">
          <div class="edit-title">客户基础信息</div>
          <el-form
            label-width="160px"
            :inline="true"
            :model="corpBaseInfo"
            class="editForm"
            :rules="corpBaseRules"
            ref="corpBaseFormE3"
          >
            <el-form-item label="客户编码" prop="corpId">
              <el-input v-model="corpBaseInfo.corpId" disabled></el-input>
            </el-form-item>
            <el-form-item label="开票合同名称" prop="systemCorpName">
              <el-input
                placeholder="开票合同名称"
                v-model.trim="corpBaseInfo.systemCorpName"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户简称" prop="corpSimName">
              <el-input
                placeholder="客户简称"
                v-model.trim="corpBaseInfo.corpSimName"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否总公司" prop="isMaster">
              <e6-vr-select
                v-model="corpBaseInfo.isMaster"
                :data="[
                  { id: 1, label: '是' },
                  { id: 2, label: '否' }
                ]"
                placeholder="是否总公司"
                title="是否总公司"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="上级公司" prop="parentCorpId">
              <select-real-Time-query
                v-model="corpBaseInfo.parentCorpId"
                :defaultDataList="parentCorpEnum"
                :needInitDropDownList="false"
                title="上级公司"
                placeholder="上级公司"
                clearable
              ></select-real-Time-query>
            </el-form-item>
            <el-form-item label="客户属性" prop="corpAttr">
              <e6-vr-select
                v-model="corpBaseInfo.corpAttr"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户来源" prop="corpSource">
              <e6-vr-select
                v-model="corpBaseInfo.corpSource"
                :data="corpSourceEnum"
                placeholder="客户来源"
                title="客户来源"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户类型" prop="corpDiff">
              <e6-vr-select
                v-model="corpBaseInfo.corpDiff"
                :data="corpDiffEnum"
                placeholder="客户类型"
                title="客户类型"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="自动确认" prop="autoConfirm">
              <e6-vr-select
                v-model="corpBaseInfo.autoConfirm"
                :data="[
                  { id: 1, label: '是' },
                  { id: 0, label: '否' }
                ]"
                placeholder="自动确认"
                title="自动确认"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户状态" prop="corpStatus">
              <e6-vr-select
                v-model="corpBaseInfo.corpStatus"
                :data="corpStatusEnum"
                placeholder="客户状态"
                title="客户状态"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户级别" prop="corpLevel">
              <e6-vr-select
                v-model="corpBaseInfo.corpLevel"
                :data="corpLevelEnum"
                placeholder="客户级别"
                title="客户级别"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="协同人" prop="cooIds">
              <all-user-search
                v-model="corpBaseInfo.cooIds"
                clearable
                placeholder="协同人"
                title="协同人"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                multiple
                :showSlot="true"
                :initValue="cooIdsInfo"
              >
              </all-user-search>
            </el-form-item>

            <el-form-item label="客户经理" prop="customerManagerId">
              <all-user-search
                v-model="corpBaseInfo.customerManagerId"
                clearable
                placeholder="客户经理"
                title="客户经理"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                :showSlot="true"
                :initValue="customermanagerInfo"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item label="费用类型" prop="corpType">
              <e6-vr-select
                v-model="corpBaseInfo.corpType"
                :data="corpFeeTypeEnum"
                placeholder="费用类型"
                title="费用类型"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="运营路线" prop="mainRoutes">
              <el-input
                placeholder="运营路线"
                v-model="corpBaseInfo.mainRoutes"
                maxlength="512"
              ></el-input>
            </el-form-item>
            <el-form-item label="安装初衷" prop="corpInstallReason">
              <e6-vr-select
                :data="corpInstallreasonEnum"
                placeholder="安装初衷"
                title="安装初衷"
                v-model="corpBaseInfo.corpInstallReason"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="主要车型" prop="mainModel">
              <el-input
                placeholder="主要车型"
                v-model="corpBaseInfo.mainModel"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户网址" prop="corpUrl">
              <el-input
                placeholder="客户网址"
                v-model="corpBaseInfo.corpUrl"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户经办人" prop="clientManager">
              <all-user-search
                v-model="corpBaseInfo.clientManager"
                clear
                placeholder="客户经办人"
                title="客户经办人"
                :propsConfig="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                :showSlot="true"
                :initValue="clientManagerList"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item label="自动冲销" prop="automaticsterilisation">
              <e6-vr-select
                v-model="corpBaseInfo.automaticsterilisation"
                :data="[
                  { id: 1, label: '是' },
                  { id: 2, label: '否' }
                ]"
                placeholder="自动冲销"
                title="自动冲销"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="客户影响力" prop="corpImpact">
              <e6-vr-select
                v-model="corpBaseInfo.corpImpact"
                :data="corpImpactEnum"
                placeholder="客户影响力"
                title="客户影响力"
                :props="propsEnum"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item label="转介绍客户" prop="corpReferral">
              <select-real-Time-query
                v-model="corpBaseInfo.corpReferral"
                :defaultDataList="corpReferralEnum"
                :needInitDropDownList="false"
                title="转介绍客户"
                placeholder="转介绍客户"
                clearable
              ></select-real-Time-query>
            </el-form-item>
            <el-form-item label="新客户截止时间">
              <el-input
                disabled
                placeholder="新客户截止时间"
                v-model="corpBaseInfo.customerexpirationdata"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="优先进入客服队列" prop="qno">
              <e6-vr-select
                v-model="corpBaseInfo.qno"
                :data="qnoListEnum"
                placeholder="优先进入客服队列"
                title="优先进入客服队列"
                clearable
                :props="{
                  id: 'qno',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item> -->
            <div></div>
            <!-- 分行 -->
            <el-form-item
              prop="icbcChangeAddr"
              label="工商变更函"
              class="contract-file"
              :rules="icbcChangeAddrRule"
            >
              <uploadOBS
                v-model="corpBaseInfo.icbcChangeAddr"
                :disabled="corpInvoiceInfo.billingCorpId === this.billingCorpId"
                :fileType="1"
                :limit="1"
                buttonText="点击上传工商变更函"
                :uploadFileList.sync="icbcChangeAddrFileList"
              >
              </uploadOBS>
            </el-form-item>
          </el-form>
        </div>
        <div class="baseInfo-box">
          <div class="edit-title">客户备注信息</div>
          <el-form
            label-width="160px"
            :inline="true"
            :model="corpBaseInfo"
            class="editForm"
          >
            <el-form-item label="业务备注" prop="remarkOp">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="业务备注"
                v-model="corpBaseInfo.remarkOp"
                style="width:350px"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务备注" prop="remarkCs">
              <el-input
                style="width:350px"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="服务备注"
                v-model="corpBaseInfo.remarkCs"
                maxlength="100"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="edit-wrapper__footer">
      <el-button class="cancel" @click="handleGoBack()">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </section>
</template>

<script>
import base from "@/mixins/base";
import goBack from "@/mixins/goBack";
import crmFieldDisabled from "@/mixins/crmCorp/crmFieldDisabled";
import rulesAndEnumsOfAddMixin from "@/views/OMS/customerManage/customerList/mixins/rulesAndEnumsOfAddMixin";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import allUserSearch from "@/components/allUserSearch";
import selectRealTimeQuery from "@/components/selectRealTimeQuery";
import { printError } from "@/utils/util";
import { updateCorp, getInvoiceDownList, getInvoiceDetail } from "@/api";
export default {
  name: "",
  components: { uploadOBS, selectRealTimeQuery, allUserSearch },
  mixins: [base, rulesAndEnumsOfAddMixin, goBack, crmFieldDisabled],
  data() {
    return {
      corpId: "",
      billingCorpId: "",
      icbcChangeAddr: "",
      getInvoiceDownList,
      corpInvoiceInfo: {
        billingCorpId: "", // 开票信息id
        bssType: "", //企业类型
        invoicePeopleType: "", //纳税人类别
        invoiceType: "1", //开票类型
        billingCorpName: "", //开票名称
        customerName: "", // 开票客户名称
        taxNo: "", //税号
        bankName: "", //开户行名称
        bankNumber: "", //开户行账号
        phone: "", // 公司电话
        address: "", //客户行地址
        fileId: "", //附件id
        fileCode: "" //附件code
      }, //开票信息
      corpBaseInfo: {
        // 客户基础信息-CRM
        systemCorpName: "", //系统客户名称
        corpName: "", //客户名称
        productLine: "", //产品线
        salesman: "", //业务员
        qno: "",
        companyId: "", //所属主体
        corpCategory: "", //客户类别
        corpBizType: "", //业务类型
        corpClass: "", //客户分级
        isMaster: "", //是否总公司
        parentCorpId: "", //上级公司
        defualtCity: "", // 省市区街道
        address: {
          provinceCode: "", // 省
          cityCode: "", // 市
          countyCode: "", // 区
          streetCode: "", // 街道
          addressDetail: "" // 详细地址
        },
        // 客户基础信息-E3
        corpId: "", //客户编码
        corpSimName: "", //客户简称
        corpAttr: [], //客户属性
        corpSource: "", //客户来源
        corpDiff: "", //客户类型

        autoConfirm: "", //自动确认
        corpStatus: "", //客户状态
        corpLevel: "", //客户级别
        cooIds: [], //协同人

        customerManagerId: "", //客户经理
        corpType: "", //费用类型
        mainRoutes: "", //运营线路 （路线）
        corpInstallReason: "", //安装初衷

        mainModel: "", //主要车型
        corpUrl: "", //客户网址
        clientManager: "", //客户经办人
        corpReferral: "", //转介绍公司

        corpImpact: "", //客户影响力
        automaticsterilisation: 2, // 自动冲销 1是2否
        icbcChangeAddr: "", // 工商变更函文件ID
        customerexpirationdata: "", // 新客户截止时间

        remarkCs: "", //服务部备注
        remarkOp: "" //业务备注
      }, //基本信息
      contactInfos: [], //客户联系人列表
      cooIdsInfo: [],
      customermanagerInfo: [],
      icbcChangeAddrFileList: [],
      clientManagerList: [],
      searchFormVisiable: false,
      activeName: "one",
      // 点击新建联系人
      addContactVisible: false
    };
  },
  computed: {
    icbcChangeAddrRule() {
      let validateIcbcChangeAddrRule = (rule, value, callback) => {
        if (this.corpInvoiceInfo.billingCorpId === this.billingCorpId) {
          callback();
        } else if (!value) {
          callback(new Error("更改开票名称后请上传工商变更函"));
        } else if (this.corpBaseInfo.icbcChangeAddr === this.icbcChangeAddr) {
          callback(new Error("更改开票名称后请重新上传工商变更函"));
        } else {
          callback();
        }
      };
      return [
        { validator: validateIcbcChangeAddrRule, trigger: ["blur", "change"] }
      ];
    }
  },
  watch: {},
  mounted() {},
  methods: {
    // 初始化数据
    initeBaseInfoData(corpBaseInfo) {
      if (!corpBaseInfo) {
        return;
      }
      corpBaseInfo = JSON.parse(JSON.stringify(corpBaseInfo));
      for (let key in corpBaseInfo) {
        corpBaseInfo[key] = corpBaseInfo[key] === null ? "" : corpBaseInfo[key];
      }
      this.corpBaseInfo = corpBaseInfo;
      // 数据来源标记 与 mixins crmFieldDisabled.js 配合使用
      this.corpSourceSystem = this.corpBaseInfo.corpSourceSystem;
      let needToStringKey = [
        "corpSource",
        "corpDiff",
        "corpCategory",
        "corpBizType",
        "corpStatus",
        "corpLevel",
        "corpClass",
        "corpImpact",
        "corpInstallReason",
        "corpType"
      ];
      needToStringKey.map(item => {
        this.corpBaseInfo[item] += "";
      });
      this.corpBaseInfo.address = this.corpBaseInfo.address || {};
      this.$set(
        this.corpBaseInfo,
        "defualtCity",
        this.getDefualtCity(this.corpBaseInfo.address)
      );
      if (this.corpBaseInfo.parentCorpId) {
        this.parentCorpEnum = [
          {
            corpId: this.corpBaseInfo.parentCorpId,
            corpName: this.corpBaseInfo.parentCorpName
          }
        ];
      }
      if (this.corpBaseInfo.corpReferral) {
        this.corpReferralEnum = [
          {
            corpId: this.corpBaseInfo.corpReferral,
            corpName: this.corpBaseInfo.corpReferralName
          }
        ];
      }
      if (this.corpBaseInfo.icbcChangeAddr) {
        this.icbcChangeAddrFileList = [
          {
            fileId: this.corpBaseInfo.icbcChangeAddr,
            name: this.corpBaseInfo.icbcChangeAddrName,
            fileUrl: this.corpBaseInfo.icbcChangeAddrUrl
          }
        ];
      }
      this.customermanagerInfo = this.corpBaseInfo.customerManagerId
        ? [
            {
              employeeId: this.corpBaseInfo.customerManagerId,
              employeeName: this.corpBaseInfo.customerManagerName,
              userName: this.corpBaseInfo.customerManagerName
            }
          ]
        : [];

      this.clientManagerList = this.corpBaseInfo.clientManager
        ? [
            {
              employeeId: this.corpBaseInfo.clientManager,
              employeeName: this.corpBaseInfo.clientManagerName,
              userName: this.corpBaseInfo.clientManagerName
            }
          ]
        : [];
      let coordList = this.corpBaseInfo.coordList.map(item => {
        item.userName = item.name;
        item.employeeId = item.id;
        return item;
      });
      this.cooIdsInfo = coordList.length ? coordList : [];
    },
    //输入协同人
    loadAllUserIncludesQuitEnum: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val, 1);
      }
    }, 300),
    // 初始化开票信息
    initInvoiceData(data = {}, corpBaseInfo) {
      this.displayInvoiceForm(data);
      this.billingCorpId = data.billingCorpId;
      this.corpId = corpBaseInfo.corpId;
      this.icbcChangeAddr = corpBaseInfo.icbcChangeAddr;
    },
    // 回显开票表单
    displayInvoiceForm(data) {
      if (!data) {
        return;
      }
      for (let key in data) {
        data[key] = data[key] === null ? "" : data[key];
      }
      data.bssType += "";
      data.invoicePeopleType += "";
      data.invoiceType += "";
      this.billingCorpNameEnum = [
        {
          billingCorpName: data.billingCorpName,
          billingCorpId: data.billingCorpId
        }
      ];
      this.corpInvoiceInfo = data;
      // if (this.corpInvoiceInfo.fileId) {
      //   this.contractFileList = [
      //     {
      //       fileId: this.corpInvoiceInfo.fileId,
      //       name: this.corpInvoiceInfo.fileName,
      //       fileUrl: this.corpInvoiceInfo.fileUrl
      //     }
      //   ];
      // }
      if (this.$refs.invoiceDataForm) {
        this.$nextTick(() => {
          this.$refs.invoiceDataForm.clearValidate();
        });
      }
    },
    // 获取地址名称
    getDefualtCity(data) {
      if (!data) {
        return;
      }
      let { provinceName, cityName, countyName, streetName } = data;
      let name = "";
      name += provinceName ? provinceName : "";
      name += cityName ? cityName : "";
      name += countyName ? countyName : "";
      name += streetName ? streetName : "";
      return name;
    },
    // 处理城市选择
    handleCitySelect(val) {
      let address = this.corpBaseInfo.address;
      if (!address) {
        return;
      }
      // 省
      address.provinceCode = val.province.code;
      address.provinceName = val.province.name;
      // 市
      address.cityCode = val.city.code;
      address.cityName = val.city.name;
      // 区
      address.countyCode = val.district.code;
      address.countyName = val.district.name;
      // 街道
      address.streetCode = val.street.code;
      address.streetName = val.street.name;
    },
    //点击确定新增
    handleSubmit() {
      this.$refs.invoiceDataForm
        .validate()
        .then(() => {
          return this.$refs.corpBaseFormCRM.validate();
        })
        .then(() => {
          return this.$refs.corpBaseFormE3.validate();
        })
        .then(() => {
          this.updateCorp();
        });
    },
    //新增客户请求
    async updateCorp() {
      try {
        this.loading = true;
        let corpInvoiceInfo = JSON.parse(JSON.stringify(this.corpInvoiceInfo));

        let res = await updateCorp({
          corpId: this.corpId,
          corpBaseInfo: this.corpBaseInfo,
          corpInvoiceInfo: corpInvoiceInfo
        });
        if (res.code === "OK") {
          this.$message.success(
            res.msg && res.msg.indexOf("审批中") !== -1
              ? "已进入飞书审批流程"
              : res.msg
          );
          this.handleGoBack(true);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 开票名称选择
    billingChange(val, data) {
      if (!data) {
        this.corpInvoiceInfo = {
          corpId: this.corpInvoiceInfo.corpId, // 客户id 客户详情里面编辑开票信息时用到
          bssType: "", //企业类型
          invoicePeopleType: "", //纳税人类别
          invoiceType: "1", //开票类型
          billingCorpId: "",
          billingCorpName: "", //开票名称
          taxNo: "", //税号
          bankName: "", //开户行名称
          bankNumber: "", //开户行账号
          address: "", //客户行地址
          fileId: "", //附件id
          fileCode: "" //附件code
        };
        // this.contractFileList = [];
        this.clearValidateInvoceForm();
      } else {
        this.getInvoiceDetail();
      }
    },
    //获取开票信息详情
    async getInvoiceDetail() {
      try {
        this.loading = true;
        let res = await getInvoiceDetail({
          billingCorpId: this.corpInvoiceInfo.billingCorpId
        });
        let data = res.data;
        this.displayInvoiceForm(data);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped></style>
