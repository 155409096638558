/*
 * @Description: CRM禁用修改字段混合
 * @Author: LiangYiNing
 * @Date: 2022-06-13 14:04:50
 * @LastEditors: LiangYiNing
 * @LastEditTime: 2022-06-17 11:39:46
 */
import setting from "../../setting";

const { CORP_SOURCE_SYSTEM_E3, CORP_SOURCE_SYSTEM_G7_CRM } = setting;

export default {
  data() {
    return {
      corpSourceSystem: CORP_SOURCE_SYSTEM_E3
    };
  },
  computed: {
    formCrmData() {
      return CORP_SOURCE_SYSTEM_G7_CRM === this.corpSourceSystem;
    }
  }
};
